import { PermissionCode, ResourceCode } from '@/const/code';

export default [
  {
    path: '/registerSubject',
    name: 'registerSubject',
    component: () => import('@/views/register-subject/RegisterSubject.vue'),
    meta: {
      resource: ResourceCode.PORTAL_REGISTER_SUBJECT,
    },
  },
]
