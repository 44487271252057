import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    studentInfo: null,
    dataExport: null,
    allFactEthnics: [],
    allFactDisabilities: [],
    allFactReligions: [],
    allFactProvinces: [],
    allFactDistricts: [],
    allFactWards: [],
    allFactNationalities: [],

    curriculumVitaeLearnings: [],
    curriculumVitaeScholarships: [],
    curriculumVitaeAwards: [],
    curriculumVitaeDisciplines: [],
    curriculumVitaePolicies: [],
    curriculumVitaeAllowances: [],
  },
  getters: {
    studentInfo: state => state.studentInfo,
    dataExport: state => state.dataExport,
    allFactEthnics: state => state.allFactEthnics,
    allFactDisabilities: state => state.allFactDisabilities,
    allFactReligions: state => state.allFactReligions,
    allFactProvinces: state => state.allFactProvinces,
    allFactDistricts: state => state.allFactDistricts,
    allFactWards: state => state.allFactWards,
    allFactNationalities: state => state.allFactNationalities,
    curriculumVitaeLearnings: state => state.curriculumVitaeLearnings,
    curriculumVitaeScholarships: state => state.curriculumVitaeScholarships,
    curriculumVitaeAwards: state => state.curriculumVitaeAwards,
    curriculumVitaeDisciplines: state => state.curriculumVitaeDisciplines,
    curriculumVitaePolicies: state => state.curriculumVitaePolicies,
    curriculumVitaeAllowances: state => state.curriculumVitaeAllowances,
  },
  mutations: {
    SET_STUDENT_INFO: (state, data) => {
      state.studentInfo = data
    },
    SET_DATA_EXPORT: (state, { dataExport }) => {
      state.dataExport = dataExport
    },
    SET_ALL: (state, { allFactEthnics, allFactDisabilities, allFactReligions, allFactProvinces, allFactNationalities }) => {
      state.allFactEthnics = allFactEthnics
      state.allFactDisabilities = allFactDisabilities
      state.allFactReligions = allFactReligions
      state.allFactProvinces = allFactProvinces
      state.allFactNationalities = allFactNationalities
    },
    SET_DISTRICT: (state, { data }) => {
      state.allFactDistricts = data
    },
    SET_WARD: (state, { data }) => {
      state.allFactWards = data
    },
    SET_CURRICULUM_VITAE_LEARNINGS: (state, data) => {
      state.curriculumVitaeLearnings = data
    },
    SET_CURRICULUM_VITAE_SCHOLARSHIPS: (state, data) => {
      state.curriculumVitaeScholarships = data
    },
    SET_CURRICULUM_VITAE_AWARDS: (state, data) => {
      state.curriculumVitaeAwards = data
    },
    SET_CURRICULUM_VITAE_DISCIPLINES: (state, data) => {
      state.curriculumVitaeDisciplines = data
    },
    SET_CURRICULUM_VITAE_POLICIES: (state, data) => {
      state.curriculumVitaePolicies = data
    },
    SET_CURRICULUM_VITAE_ALLOWANCES: (state, data) => {
      state.curriculumVitaeAllowances = data
    },
  },
  actions: {
    async begin({ commit }) {
      try {
        const rsFactEthnics = await axios.get('uni/student-info/selectAllFactEthnics')
        const rsFactDisabilities = await axios.get('uni/student-info/selectAllFactDisabilities')
        const rsFactReligions = await axios.get('uni/student-info/selectAllFactReligions')
        const rsFactProvinces = await axios.get('uni/student-info/selectAllFactProvinces')
        const rsFactNationalities = await axios.get('uni/student-info/selectAllFactNationalities')

        if (rsFactEthnics.data.code === ApiCode.SUCCESS && rsFactDisabilities.data.code === ApiCode.SUCCESS && rsFactReligions.data.code === ApiCode.SUCCESS && rsFactProvinces.data.code === ApiCode.SUCCESS && rsFactNationalities.data.code === ApiCode.SUCCESS) {
          commit('SET_ALL', { allFactEthnics: rsFactEthnics.data.data.data, allFactDisabilities: rsFactDisabilities.data.data.data, allFactReligions: rsFactReligions.data.data.data, allFactProvinces: rsFactProvinces.data.data.data, allFactNationalities: rsFactNationalities.data.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStudentInfo({ commit }, params) {
      try {
        const response = await axios.get('uni/student-info', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            info,
            learnings,
            scholarships,
            awards,
            disciplines,
            policies,
            allowances,
          } = data.data
          commit('SET_STUDENT_INFO', info)
          commit('SET_CURRICULUM_VITAE_LEARNINGS', learnings)
          commit('SET_CURRICULUM_VITAE_SCHOLARSHIPS', scholarships)
          commit('SET_CURRICULUM_VITAE_AWARDS', awards)
          commit('SET_CURRICULUM_VITAE_DISCIPLINES', disciplines)
          commit('SET_CURRICULUM_VITAE_POLICIES', policies)
          commit('SET_CURRICULUM_VITAE_ALLOWANCES', allowances)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDistrict({ commit }, params) {
      try {
        const rs = await axios.get('uni/student-info/selectAllFactDistricts', { params })
        if (rs.data.code === ApiCode.SUCCESS) {
          commit('SET_DISTRICT', { data: rs.data.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getWard({ commit }, params) {
      try {
        const rs = await axios.get('uni/student-info/selectAllFactWards', { params })
        if (rs.data.code === ApiCode.SUCCESS) {
          commit('SET_WARD', { data: rs.data.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExport({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/student-info/export-ho-so',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateStudentInfo({ commit }, params) {
      try {
        const response = await axios.put(`uni/student-info/${params.student_id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
